<template>
 
  	<div class="hoverCard" @click="goToLink()" v-bind:class="{ success: approved, danger: none, warning: pending, default: blank }" style="color:inherit;">
       <a :href="url">

      <span class="flex justify-space-between">
    		<h3 class="mb-2">{{title}}</h3>
        <span v-if="approved && (status && status != null)" class="caption strong flex">Completed <i class="fa-solid fa-check ml-3" style="font-size:2rem;"></i></span>
        <span v-if="pending && (status && status != null)" class="caption strong flex"><span style="width:100px; text-align:right;">Under Review</span> <i class="fa-solid fa-user-magnifying-glass ml-3" style="font-size:2rem;"></i></span>
        <span v-if="none && (status && status != null)" class="caption strong" style="display:flex;"><span style="width:100px; text-align:right;">Not Started</span> <i class="fa-solid fa-circle-exclamation ml-3" style="font-size:2rem;"></i></span>
      </span>

  		<div>{{description}}</div>
      </a>
    </div>
  
</template>

<script>
import router from '@/router'

export default {
  props: ['status', 'url', 'title', 'description'],
  computed: {
    blank() {
      return this.status && this.status == "blank"
    },
    none() {
      return !this.status
    },
    pending() {
      return this.status && this.status == "submitted"
    },
    approved() {
      return this.status && this.status == "approved"
    }
  },
  methods: {
    goToLink() {
      if (this.url && this.url != null) {
        let url = this.url
        router.push(url)
      }
      if (this.url && this.blank) {
        let url = this.url
        router.push(url)
      }
    }
  }
}
</script>