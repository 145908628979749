<template>
	<div class="dashboard">
    <div class="dashboard__container mb-5 pb-5">
      <div class="dashboard__container--header pa-0">
        <h1>My Uploads</h1>

      </div>
      <hr>
      <div class="dashboard__container--body pt-3">
        <div class="dashboard__container--body--col flex">
          <Card :status="hasPhoto" :url="`account/photos`" :title="`Upload Profile Pictures`" :description="`Upload your avatar, as well as additional photos, head shots and full body shots.`" />
        </div>
        <div class="dashboard__container--body--col flex">
          <Card :status="hasHarassment" :url="`account/harassment`" :title="`Sexual Harassment Prevention Training`" :description="`You must complete your Sexual Harassment Prevention Training to work in California. Please uplaod your certificate when complete. Click for details.`" />
        </div>
        <div class="dashboard__container--body--col flex">
          <Card :status="hasCerts" :url="`account/uploads`" :title="`Upload Certifications`" :description="`Upload your food handlers certification, alcohol awareness, forklift driver's certification, and more.`" />
        </div>
        <div class="dashboard__container--body--col flex">
          <Card :status="hasID" :url="`account/id`" :title="`Upload My ID`" :description="`Upload a picture of your driver's license or other government-issued identification. This helps us confirm your identity and check-in to work.`" />
        </div>
        <div class="dashboard__container--body--col flex">
          <Card :status="hasResume" :url="`account/resume`" :title="`Upload My Resumé`" :description="`Stand out from the crowd by uploading your resumé. This is a requirement for many positions.`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Card from '@/components/Modules/Card.vue'

export default {
  name: 'uploads',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    hasID() {
      if (this.userProfile && this.userProfile.id_card_path) {
        return "approved"
      } else {
        return false
      }
    },
    hasResume() {
      if (this.userProfile && this.userProfile.picture) {
        return "approved"
      } else {
        return false
      }
    },
    hasCerts() {
      if (this.userProfile && this.userProfile.certs && this.userProfile.certs.length >= 1) {
        return "approved"
      } else {
        return false
      }
    },
    hasPhoto() {
      if (this.userProfile && this.userProfile.photoUrl) {
        return "approved"
      } else {
        return false
      }
    },
    hasHarassment() {
      if (this.userProfile && this.userProfile.harassmentCerts && this.userProfile.harassmentCerts.length >= 1 && this.userProfile.employeeId && (this.userProfile.address && this.userProfile.address.state && this.userProfile.address.state == 'CA')) {
        return "approved"
      } else {
        return false
      }
    }
  },
  components: {
    Loader,
    Card
  },
  
  beforeDestroy () {
  }
}
</script>